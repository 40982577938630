/* -------------------section02--------------------- */
.history-section{
    margin-top: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-section .text-box{
    border-radius: 37px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(144, 49, 86, 0.40);
    padding: 7% 3% 7% 2%;
    margin-right: -5%;
    z-index: 0;
}
.history-section .image-box img{
    max-width: 100%;
    border-radius:30px;
}
@media (min-width: 1200px) {
    .text-box-style{ text-align: left !important; }
}
@media (max-width: 1200px) {
    .history-section{
        margin-top: 0;
    }
    .history-section .image-box img{
        display: none;
    }
    .history-section .text-box{
        border-radius: 25px;
        margin-right: 0;
         /* width: 95%; */
        margin-top: 2%;
}
}

/* -------------------section03--------------------- */
.image-card{ 
    position: relative;
    /* max-height: 455px; */
    border-radius: 25px;
    margin: 0 5px;
}
.image-card .img-fluid{border-radius: 25px;}
.title-box{
    position: absolute;
    height: 69px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(16px);
    bottom: 4%;
    left: 5%;
    color: #8F3055;
    font-family: Anybody-bold;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 0 7%;
}

@media (max-width: 992px ) and (min-width: 768px) {.title-box{left: 3%;}}

@media (max-width: 767px) {
    .image-card{ 
        border-radius: 15px;
    }
    .image-card .img-fluid{border-radius: 15px;}
    .title-box{
        font-size: 18px;
        border-radius: 10px;
        height: 39px;
    }

    .title-box-svg svg {
        width: 14px;
        height: 14px;
    }
    
}

ul.react-multi-carousel-track{margin-bottom: 40px;}
.react-multi-carousel-dot button {
    background: #C1BEBE;
    border-color: #C1BEBE;
}
.react-multi-carousel-dot--active button {
    background: #4E4E4E;
    border-color: #4E4E4E;
}
  

  

/* -------------------section04--------------------- */

.data-card{
    border-radius: 37px;
    background: #FFF;
    box-shadow: 0px 4px 15px 0px rgba(144, 49, 86, 0.40);
    height: 240px;
    padding: 11% 8%;
    margin: 4% 0;
}
.data-card .circle{
    width: 66px;
    height: 66px;
    background-color: #8F3055;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: 'Poppins';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}
.data-card .title{
    color: #323232;
    font-family: 'Poppins-semibold';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize;
}
.data-card .text{ 
    color: #5F5F5F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}


@media (max-width: 767px) {
    .data-card{
        border-radius: 25px;
        height: 172px;
        padding: 12% 8%;
    }
    .data-card .circle{
        width: 47px;
        height: 47px;
        font-size: 18px;
        margin-bottom: 5px;
    }
    .data-card .title{
        font-size: 14px;
        line-height: 18px !important;
    }
    .data-card .text{ 
        font-size: 8px;
        line-height: 13px;
    }



    
    .data-cards-component .row  >*{
        padding: 0 6px !important;
    }
}

@media (max-width: 575px) {
    .data-cards-component .row {
        padding: 0 6px !important;
    }
    .data-card .title{
        font-size: 12px;
    }
    .data-card{
        border-radius: 25px;
        height: 172px;
        padding: 8%;
    }
}