.sub-service-page .form-box{ 
    width: 100%; max-height: 557px; border-radius: 37px; background: #FFF; 
    box-shadow: 0px 4px 4px 0px rgba(144, 49, 86, 0.40); padding: 30px 95px; 
}
.sub-service-page .image-block img{ max-width: 100%; height: 100%;border-radius:45px;}
.sub-service-page .banner_mobile{ display: none; }
.sub-service-page .second-sec{ display: flex; align-items: center; }

@media (max-width: 1200px) { .sub-service-page .form-box{padding: 20px 40px} }
@media (max-width: 1024px) { .sub-service-page .form-box{ padding: 30px 20px 20px 20px !important; max-height: 500px;} }
@media (max-width: 991px) {.sub-service-page .image-block{ display: none;} }
@media (max-width: 500px) {
    .sub-service-page .banner_desk{ display: none;}
    .sub-service-page .banner_mobile{ display: block; min-width: 100%; height: 100%;}
}

.service-banner{
    border-radius:45px;
}