
@media (max-width : 992px) {.section04{display: none;}}

.cc-tabs{height: 535px; overflow: hidden;}


/* svg{vertical-align: text-top;} */

.tabbed-navigation {
    display: flex;
  }
  
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Adjust alignment as needed */
    margin-top: 40px;
  }
  
  .tab {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    position: relative; /* Add this line */
  }
  
  .bullet {
    width: 14px; 
    height: 14px; 
    margin-right: 25px;
  }

  
  
  .bullet-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  
 
  .line {
    position: absolute;
    top: 20px;
    left: 6px; 
    width: 2px;
    height: calc(100% + 28px); 
    background-image: repeating-linear-gradient(to bottom, transparent, transparent 4px, #D0AEBB 4px, #D0AEBB 8px);
  }
  
  
  .tab-link {
    color: #626262;
    text-align: left;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .tab-link.active {
    color: #323232;
    font-weight: 500;
    /* background-color: #ddd; */
  }
  
  .tab-content {
    padding: 10px;
    flex-grow: 1;
  }
  
  .tab-pane {
    display: none;
  }
  
  .tab-pane.active {
    display: block;
  }