.rahship-form{ border-radius: 37px; background: #FFF; margin-top: 45px;
  box-shadow: 0px 4px 4px 0px rgba(144, 49, 86, 0.40); padding: 50px 95px; }
.rahship-form-01{ border-radius: 35px; background: rgba(255, 255, 255, 0.70); border-radius: 37px; width: 100%;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15); backdrop-filter: blur(16px);  padding: 30px;}

@media (min-width: 1400px) {.rahship-form{min-height: 557px;} }
@media (max-width: 1200px) { .rahship-form, .rahship-form-01{padding: 25px 40px} }
@media (max-width: 1024px) { .rahship-form, .rahship-form-01{ padding: 15px 20px 20px 20px !important; max-height: 600px;} }


/* ------------------------ */ 
/* .p-radio{ display: flex; justify-content: center; text-align: center;}
.p-radio .col{ color: #898989; font-family: Poppins; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;} */

/* ------------- Tabs_Form.jsx ------------ */
.form-tabs{ display: flex; justify-content: space-between; text-align: center; margin: 10px; cursor: pointer;}
.form-tabs div{ color: #898989; font-family: Poppins; font-size: 16px; font-style: normal; font-weight: 500; line-height: normal;}
.form-tab.active-tab{ color: #8F3055; border-bottom: 1px solid #8F3055; }

/* ------------- Input_field.jsx ------------ */
  .form-input-field{ margin-top: 17px; padding: 8px 18px; border: none; width: 100%; height: 40px; border-radius: 12px; 
    background: rgba(176, 176, 176, 0.20); backdrop-filter: blur(12.5px);}
  .form-input-field.country-dropdown option, 
  .form-input-field::placeholder,
  .form-input-field{ color: #575656 !important; font-family: Poppins-light; font-size: 16px; font-style: normal;
    font-weight: 300; line-height: normal;}
  .form-input-field:focus-visible {outline-color: #8F3055;}
  /* .css-b62m3t-container:focus-visible {outline: none !important; border: 2px solid green;} */
  input:-internal-autofill-selected {appearance: none; background: #8F3055 !important;}

  .css-b62m3t-container{ margin-top: 15px !important;}
  .css-13cymwt-control{border: none !important; width: 100%; height: 40px; border-radius: 12px !important; 
    background: rgba(176, 176, 176, 0.20) !important; backdrop-filter: blur(12.5px) !important;
    color: #575656 !important;
    font-family: Poppins-light !important;
    font-size: 16px !important;
    font-weight: 300 !important;
  }
  .css-1fdsijx-ValueContainer { padding: 2px 16px !important;}
  .css-hlgwow { padding: 2px 15px !important;}
  .css-1jqq78o-placeholder { color: #575656!important; }

/* ------------- Submit_button.jsx ------------ */
  .submit-button{ display: flex; border: none; width: 100%; padding: 8px ; justify-content: center; gap: 10px;
    align-items: center; border-radius: 12px; background: #8F3055; color: #FFF; line-height: 24px;
    font-family: Poppins; font-size: 16px; font-style: normal; font-weight: 400; margin: 24px 0;}

/* ------------- Import_Export_radio.jsx ------------ */
  .import-export-radio { display: flex; justify-content: center; gap: 20px; margin: 0 0 14px 0; position: relative;}
  .radio-option { height: 36px; width: 167px; cursor: pointer; color: #A5A5A5; font-family: Poppins; font-size: 17px;
    font-style: normal; font-weight: 400; line-height: 24px; display: flex; align-items: center; justify-content: center;}
  .radio-option.selected { border-radius: 12px; background: #903156; color: #FFF;}




  

  

  
  .popup { display: none; align-items: center; justify-content: center; max-width: 100%; height: 100%;
    top: 0; left: 0; background-color: transparent; z-index: 1000; border-radius: 15px;}
  .popup-message { background-color: #8F3055; padding: 8px 2px; border-radius: 15px; text-align: center;
    color: #ffffff; margin-top: -7px !important; width: 100%; font-family: 'poppins';}
  .loader { display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;
    top: 0; left: 0; background-color: transparent; z-index: 1000; color: #8F3055;}
  .success, .error { display: flex; }


  .contactInput::-webkit-inner-spin-button, .contactInput::-webkit-outer-spin-button {
      -webkit-appearance: none; appearance: none; margin: 0;
    }

  