.desktop-header, .mobile-header{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background: #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
}

  
  .cc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;
    margin: 0 auto;
    height: 96px;
  }
  
  .navList {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .navItem {
    margin-right: 60px;
  }
  
  .navLink {
    text-decoration: none;
    color: #888;
    font-family: Poppins;  
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  .navLink.active {
    border-bottom: #8F3055 1px solid;
    color: #8F3055;
  }

  @media (max-width: 991px) {
    .navLink.active {
      border-bottom: 0;
    }
  }

.sub-services-navLinks {
  display: none;
  position: absolute;
  top: 100%;
  left: -20%;
  padding:  0;
  z-index: 2; 
  min-width: 17em;
  max-width: 1000px;
  list-style: none;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #FFF;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  overflow-y: hidden;
}

.sub-services-navLinks .navItem{
  color: #1D1D1D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 10px;
  border-bottom: 1px solid rgba(163, 163, 163, 0.25);
  width: 100%;
  margin: 0;
}
.sub-services-navLinks .navItem:hover{
  background-color: #8F3055;
}
.sub-services-navLinks .navItem:hover a{
  color: #fff;
}

.services-navLink:hover .sub-services-navLinks {
  display: block; 
}

.services-navLink {
  position: relative;
  z-index: 1;
}

.sub-services-navLinks .navLink.active{
  font-weight: 600;
}




   /* --------------------button component-------- */

   .button-cc{
    display: flex;
    border: none;
    width: 190px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: #8F3055;

    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
   }


   

  /* ------------------------------------------------------------------------ */
  /* ---------------------------------------------------------------------------- */
  
/* Styles for the mobile header */
  @media (max-width: 991px) {
    .mobile-header {
      background-color: #fff;
      padding: 20px;
      /* position: relative; */
  }

  .drawer-button {
    font-size: 24px;
    cursor: pointer;
    border: none;
    background: none;
    position: absolute;
    top: 33px;
    left: 10px;
  }

  .mobile-header-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .brand img {
    max-width: 180px; 
  }

  /* Drawer styles */
  .navList {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 106px;
    left: -100%;
    width: 95%;
    height: 900px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: left 0.4s ease-in-out;
    display: block;
  }

  .navList li{
    border-bottom: 1px solid rgba(163, 163, 163, 0.25);
    padding: 10px;
    width: 100%;
  }

  .navList.show {
    left: 0;
  }

  .navLink {
    width: 100%; 
    padding: 0 20px;
    text-decoration: none;
    color: #333;
    /* border-bottom: 1px solid #8F3055; */
  }
}

/* ---------------------------------------------------------------------------- */
li.navItem.services-navLink{ padding: 0;}
.services-navLink .subNavlinks-list{padding: 0; }
button.sub-services-mobile{ padding: 10px !important;}
button.sub-services-mobile svg.float-end{ background: #8F3055; margin-top: 5px;}
.subNavlinks-list li{padding-left: 60px;}
.subNavlinks-list .navLink{padding: 0 !important; -webkit-padding: 0 !important;}
ul.subNavlinks-list { list-style: none;}

.mobile-Footer{
  background-color: #212121;
  padding: 64px 22px;
}

.navlink-dropdown button{
  border: none;
  background: transparent;
  width: 100%;
  margin: 0;
  text-align: start;
  padding: 0;
}