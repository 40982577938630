.primary-banner{
    position: relative;
    border-radius: 0px 0px 35px 35px;
}
.primary-banner .primary-banner-desk,
.primary-banner .primary-banner-mob{
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
}
.primary-banner .primary-banner-mob{ display: none; height: 209px;}
.primary-banner-text{
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-family: Anybody;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .primary-banner-text{ font-size: 29px; margin-left: 15px;}
}
@media (max-width: 600px) {
    .primary-banner{ height: 209px; padding: 0;}
    .primary-banner .primary-banner-mob{display: block;}
    .primary-banner .primary-banner-desk{display: none;}
}
