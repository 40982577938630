/* ------------  footer.jsx ---------- */
.desktop-footer{
    background-color: #212121;
    height: 420px;
    display: flex;
    justify-content: space-between;
    padding: 82px 30px;
    /* text-align: center; */
}



/* ------------  Column01.jsx ---------- */
.fields_div{padding: 40px 0;}



/* ------------  Column02.jsx ---------- */






.subFooter{display: flex; align-items: center;}


/* ------------  Footer_field.jsx ---------- */
.footer_field_data{
    color: #EFEFEF;
    font-family: Poppins-light;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px; /* 178.571% */
    margin: 5px;
    padding: 0 12px;
}


/* sec2 */
.sub_section_header{
    color: #EFEFEF;
    font-family: Anybody;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    margin-bottom: 20px;
}

@media (min-width : 992px) {
    .mt-40{
    margin-top: 35px;
}



.footer_column01 { min-width: 25%; display: flex; justify-content: center;}

.footer_column03 { min-width: 25%; display: flex; justify-content: center;}
}

@media (min-width : 1248px) {
    .footer_column02 { max-width: 40%; display: flex; justify-content: center;}
}

@media (max-width : 991px) {
    .mt-40{padding: 6px; -webkit-padding: 6px !important;}
}


.flex-box{
    display: flex;
}


.copyright{
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-align: center;
}







/* ------------  Mobile_Footer.jsx ---------- */
.mobile-Footer{
    background-color: #212121;
    padding: 64px 22px;
}

.mobile_footer_dropdown button{
    border: none;
    background: transparent;
    width: 100%;
    margin: 5px 0;
    text-align: start;
    border-bottom: 1px solid rgba(232, 232, 232, 0.50);
}