

/* -----------------Title_Text.jsx--------------- */
@media (max-width: 1200px) {.center-section .text { width: 95% !important;} }

/* -----------------section03--------------- */
.home-section-03{ 
    background-image: url(../../../assets/media/home/home-section-03-desk-bg.webp);
    background-size: cover; background-repeat: no-repeat; height: 486px; 
    display: flex; justify-content: center; align-items: center;
}
.p-center-section{ 
    border-radius: 37px; padding: 40px 0; margin: 0 20px;
    background: rgba(255, 255, 255, 0.85); width: 70%;
    box-shadow: 0px 4px 4px 0px rgba(144, 49, 86, 0.40);
    display: flex; justify-content: center; align-items: center; 
}



@media (max-width: 767px) {
    /* -----------------Title_Text.jsx--------------- */
    .center-section .title{font-size: 25px !important;}
    .center-section .text{font-size: 13px !important; padding: 0 10px !important;}
      
    /* -----------------section03--------------- */
    .p-center-section{width: 95%; padding: 75px 0;}
}


