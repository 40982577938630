.section02-card{ display: flex; align-items: center;}
.section02-card .image-box{ margin-right: 2.7%;}
.section02-card .title{ 
    color: #8F3055; font-family: Anybody-bold; font-size: 40px; font-style: normal;
    font-weight: 700; line-height: normal; text-transform: uppercase;
}
.section02-card .text{
    color: #5F5F5F; font-family: Poppins; font-size: 16px;
    font-style: normal; font-weight: 400; line-height: 24px; 
}
.section02-card img{ width: 160px; height: 150px; border-radius: 25px; }

@media (max-width: 1200px) { .row .card-col{ width: 100% !important; margin-bottom: 20px; }}
@media (max-width: 767px) {
    .section02-card .title{ font-size: 25px;}
    .section02-card .text{ font-size: 10px; line-height: 14px; }
    .section02-card img{ width: 101px; height: 95px; border-radius: 18px;}
}