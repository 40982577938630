.text-center.center-section .text{
    color: #5F5F5F;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 767px) {
    .text-center.center-section .text{
        font-size: 13px !important;
        line-height: 20px !important;
    }
}