.mb-global{ margin-bottom: 65px;}
@media (max-width: 992px) { .mb-global{ margin-bottom: 42px;} }

@font-face {
    font-family: 'Anybody';
    src: url(../public/fonts/Anybody-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Anybody-bold';
    src: url(../public/fonts/Anybody-Bold.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins';
    src: url('../public/fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-light';
    src: url('../public/fonts/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Poppins-semibold';
    src: url('../public/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
