.desktop-form{ display: none;}
.home-mobile-banner-image{ display: none;}

/* --------------banner-text------------- */
.banner-text { 
    text-shadow: 0px 8px 14px rgba(0, 0, 0, 0.10);
    font-family: 'Anybody-bold';
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    width: 480px;
    top: 25%;  
  }

.banner-black-span{
    color: #FFF;
}
.banner-purple-span{
    color: #903156;
    text-shadow: none;
}


/* ---------banner form------------- */
.banner-form{
    position: absolute;
    top: 7%;
    right: 70px;
    z-index: 100;
    width:30%;
}


@media (min-width: 992px) {
    .banner-text {left: 61px;}
}
@media (max-width: 1140px) {
    .banner-form{
        position: absolute;
        top: 0;
        right: 60px;
    }
}
@media (max-width: 1320px) {
    .banner-text {top: 22%;}
}
@media (max-width: 1200px) {
    .banner-text { font-size: 50px !important;  top: 16%; left: 50px; width: 380px;}
}
@media (max-width: 991px) {
    .banner-form{ display: none; } 
    .desktop-form{ display: block; }
    .banner-text {
        font-size: 45px !important; 
        width: 100%;
        top: 10%;
        text-align: center;
        padding: 0 15%;
        left: 0;
    }
}
@media (max-width: 625px) {
    .banner-text {font-size: 30px !important; padding: 0 7%;}
    .home-mobile-banner-image{ display: block;}
    .home-desktop-banner-image{display: none;}
}


/* ---------Carousel Slider------------- */
.carousel-indicators{
    display:none;
}
.carousel-control-prev-icon{
    position: absolute;
    left:0
}
.carousel-control-next-icon{
    position: absolute;
    right:0
}
/* .carousel-control-next, .carousel-control-prev {
    width: 5% !important;
} */
.home-section-2-img{
    object-fit: cover;
    border-radius:45px;
}